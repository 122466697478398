import { useCreatorConfig } from '../../providers/ConfigProvider';
import useContentAnalytics from '../../hooks/useContentAnalytics.tsx';
import { Link } from 'react-router-dom';
import { PlayCircleIcon } from '@heroicons/react/24/outline';
import useContent from '../../hooks/useContent.tsx';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { CourseProductSection } from '../../types/course-product.ts';
import Loader from '../_base/Loader';
import Pill from '../_base/Pill';
import Button from '../_base/Button';
import PrimaryText from '../_base/PrimaryText';

const CourseProgressCard = ({ courseId }: { courseId: string }) => {
    const { t } = useTranslation();
    const { theme } = useCreatorConfig();
    const { data: course, isLoading } = useContent({ type: 'course', productId: courseId as string });
    const {
        latestWatchedLesson,
        latestWatchedLessonPosition,
        courseProgressPercentage,
        totalLessons,
        isLoading: courseAnalyticsIsLoading,
    } = useContentAnalytics({ productId: courseId });
    const modules = orderBy(course?.sections, ['order'], ['asc']) as CourseProductSection[];

    const bannerImageSrc = course?.images?.[0]?.url || theme?.assets?.genericBannerUrl;

    return (
        <div
            className="relative rounded-xl overflow-hidden border"
            style={{
                borderColor: theme?.colours?.secondaryColour,
            }}>
            <div
                className="h-64"
                style={{
                    backgroundColor: theme?.colours?.secondaryColour,
                }}>
                <img src={bannerImageSrc} className="h-64 lg:h-auto hidden md:block" />
            </div>

            {(isLoading || courseAnalyticsIsLoading) && (
                <div className="absolute top-0 bottom-0 left-10 bg flex items-center justify-center">
                    <Loader />
                </div>
            )}
            {!isLoading && !courseAnalyticsIsLoading && (
                <div className="absolute top-5 bottom-5 left-5 flex flex-col justify-center gap-4 right-5 lg:end-1/2">
                    <PrimaryText className="text-2xl font-bold">{course?.name}</PrimaryText>

                    <div className="flex items-center gap-2">
                        <Pill label={`${latestWatchedLessonPosition ?? 0}/${totalLessons} ${t('lessons')}`} />
                        <div className="flex-1 flex items-center gap-4">
                            <div className="w-full bg-gray-400 rounded-full h-2.5 ">
                                <div
                                    className="h-2.5 rounded-full"
                                    style={{
                                        backgroundColor: theme?.colours?.primaryColour,
                                        width: `${courseProgressPercentage}%`,
                                    }}></div>
                            </div>
                            <PrimaryText className="text-sm">{`${courseProgressPercentage}%`}</PrimaryText>
                        </div>
                    </div>

                    <Link
                        to={
                            latestWatchedLesson
                                ? `/courses/${courseId}/modules/${latestWatchedLesson?.section?.id}/lessons/${latestWatchedLesson?.id}`
                                : `/courses/${courseId}/modules/${modules?.[0]?.id}`
                        }>
                        <div className="mt-4 flex flex-row items-center gap-4">
                            {latestWatchedLesson && (
                                <div>
                                    <div
                                        className="aspect-video w-[100px] md:w-[120px] rounded-xl overflow-hidden relative border"
                                        style={{
                                            borderColor: theme?.colours?.secondaryColour,
                                        }}>
                                        <img
                                            src={
                                                latestWatchedLesson?.assets?.find(
                                                    ({ asset_type }) => asset_type?.name === 'Secure Video',
                                                )?.thumbnail
                                            }
                                            className="object-cover object-center absolute"
                                        />
                                        <PlayCircleIcon className="h-8 w-8 absolute text-gray-100 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                                    </div>
                                </div>
                            )}
                            <div>
                                <PrimaryText inlineBlock className="mb-2 text-xs">
                                    {latestWatchedLesson?.name}
                                </PrimaryText>

                                <Button isPrimary size="xs">
                                    <PlayCircleIcon className="mr-2 h-6 w-6" />
                                    {(latestWatchedLessonPosition || 0) > 0
                                        ? t('Continue watching')
                                        : t('Start watching')}
                                </Button>
                            </div>
                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default CourseProgressCard;
